import { sendPost, sendDel } from './index'
import type { QueryData, ListRes } from './type'

// 地址类型
export interface AddressType {
    address1: string,
    address2: string,
    city: string,
    companyName: string,
    country: string,
    email: string,
    fullAddress: string,
    name: string,
    phone: string,
    state: string,
    zip: string,
    alias:string
}
// 获取地址列表
export interface GetAddressListRes extends AddressType {
    id: number,
    createdAt: string,
    hashCode: string,
    company: {
        companyName: string
        customerCode: string
    }
    companyId: string | number
}
// 添加产品关联地址
interface AddProductAddressData {
    addressType: string
    productCode: string
    name: string
    companyName: string
    address1: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    phone: string
    email: string
}

export const addressModule = {
    //获取地址列表
    getAddressList: (data: QueryData) => sendPost<ListRes<GetAddressListRes>>('/address/list', data),
    //添加地址
    addAddress: (data: any) => sendPost('/address/create', data),
    //修改地址
    editAddress: (data: any, id: any) => sendPost(`/address/update/${id}`, data),
    //删除地址
    dleAddress: (id: number) => sendDel(`/address/delete/${id}`),
    // 获取产品关联的地址列表
    getProductAddress: (data: QueryData) => sendPost<ListRes<GetAddressListRes>>('/admin/address/list', data),
    // 添加产品关联地址
    addProductAddress: (data: AddProductAddressData) => sendPost('/admin/address/create', data),
    // 修改产品关联地址
    editProductAddress: (id: number, data: AddProductAddressData) => sendPost(`/admin/address/update/${id}`, data),
    // 删除产品关联地址
    delProductAddress: (id: number) => sendDel(`/admin/address/delete/${id}`)
}