import { sendGet, sendPost } from './index'
import type { QueryData, ListRes } from './type'

// 获取公司列表 管理
export interface CompanyListSolutionsType {
    newPlan: string,
    newSolution: string,
    plan: string,
    productId: number,
    solution: string,
    startTime: null | string
    allowCancel: boolean
    allowRetry: boolean
    cancelLimitTime: number
    retryLimitTime: number
}
export interface GetCompanyListRes {
    companyName: string,
    contact: string,
    createdAt: string,
    customerCode: string,
    desc: string,
    siteTag: string,
    recreate: boolean,
    id: number,
    solutions: CompanyListSolutionsType[]
}
// 获取产品和估价方案
export interface ProductsType {
    id: number,
    carrier: string,
    productCode: string,
    productName: string
}
export interface SolutionsType {
    plans: { planName: string, planCode: string }[],
    solutionName: string
    solutionCode: string
}
export interface GetSchemeRes {
    products: ProductsType[],
    solutions: SolutionsType[]
}
// 获取公司列表 用于查询
export interface GetCompanyListQueryRes {
    id: number,
    companyName: string,
    customerCode: string
}
// 更新公司 管理
export interface AdminUpdateCompanyData {
    companyName: string,
    customerCode: string,
    siteTag: string,
    desc: string
}
export interface AdminUpdateCompanyRes {
    companyName: string,
    customerCode: string,
    siteTag: string,
    desc: string
}
// 添加访问限制
export interface AddRestrictData {
    key: "site-tag",
    value: string
}
// 预约报价
export interface AppointmentQuotationRes {
    companyId: number
    createdAt: string
    id: number
    newPlan: string
    newSolution: string
    plan: string
    productId: number
    solution: string
    startTime: string
    updatedAt: string
}

export const companyModule = {
    // 产品绑定 管理
    productBinding: (data: any) => sendPost('/admin/product/bind-solutions', data),
    //获取公司列表 管理
    getCompanyList: (data: QueryData) => sendPost<ListRes<GetCompanyListRes>>('/admin/company/list', data),
    // 获取产品和估价方案
    getScheme: () => sendGet<GetSchemeRes>('/admin/product/solutions'),
    //添加公司 管理
    addCompany: (data: any) => sendPost('/admin/company/create', data),
    // 更新公司 管理
    adminUpdateCompany: (id: number, data: AdminUpdateCompanyData) => sendPost<AdminUpdateCompanyRes>(`/admin/company/update/${id}`, data),
    // 获取访问限制列表
    getSiteLabel: () => sendGet<string[]>('/config/site-tag/set'),
    // 获取公司列表 用于查询
    getCompanyListQuery: () => sendGet<GetCompanyListQueryRes[]>(`/admin/company/list`),
    // 添加访问限制
    addRestrict: (data: AddRestrictData) => sendPost('/admin/config/create', data),
    //预约报价
    appointmentQuotation: (companyId: number, productId: number, data: any) => sendPost<AppointmentQuotationRes>(`/admin/product/bind/${companyId}/${productId}`, data)
}
